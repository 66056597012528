// import { GAM } from '@/components/icons';
import React from 'react';

const LoginLayout = ({ content }) => {
  return (
    <div className="flex flex-col overflow-x-hidden  overflow-y-auto h-screen full-screen-gradient">
      <span className="flex items-center cursor-pointer pt-24 mx-auto">{/* <GAM /> */}</span>
      <div className="flex flex-col items-center rounded-3xl flex-1 mx-auto max-w-[90vw] md:max-w-1/2 xl:px-12 px-6 xl:w-[568px] text-sm lg:text-base sm:w-[448px]">
        {content}
      </div>
    </div>
  );
};

export default LoginLayout;
