import Title from '@/components/common/modal/Title';
import { Button, Form, Modal } from '@/UI';
import CustomInput from '@/components/common/CustomInput';

export const UpdatePassword = ({ open, onClose }) => {
  return (
    <Modal
      className="custom-login-modal"
      title={<Title label="Đặt lại mật khẩu" onCancel={onClose} />}
      footer={null}
      closable={false}
      onCancel={onClose}
      width={500}
      open={open}
      destroyOnClose
      centered
    >
      <Form className="p-4">
        <Form.Item>
          <CustomInput type="password" placeholder="Nhập mật khẩu mới" />
        </Form.Item>
        <Form.Item>
          <CustomInput type="password" placeholder="Nhập lại mật khẩu" />
        </Form.Item>
        <Form.Item>
          <Button
            size="large"
            className="w-full h-[44px]"
            type="primary"
            htmlType="submit"
            onClick={() => {}}
          >
            Tiếp tục
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
