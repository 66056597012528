import Title from '@/components/common/modal/Title';
import { Button, Form, Modal, Radio } from '@/UI';
import { useState } from 'react';
import { SendingOtp } from './SendingOtp';
import CustomInput from '@/components/common/CustomInput';

export const ForgottenPassword = ({ onClose, open }) => {
  const [showSendingOtp, setShowSendingOtp] = useState(false);

  const handleShowSendingOtp = () => {
    setShowSendingOtp(true);
  };

  const handleCloseSendingOtp = () => {
    setShowSendingOtp(false);
  };
  return (
    <>
      <Modal
        className="custom-login-modal"
        title={<Title label="Quên mật khẩu" onCancel={onClose} />}
        footer={null}
        closable={false}
        onCancel={onClose}
        width={500}
        open={open}
        centered
        destroyOnClose
      >
        <Form className="p-5">
          <p className="mb-2 font-medium">
            Vui lòng nhập Số điện thoại/Email tài khoản muốn khôi phục mật khẩu
          </p>
          <Form.Item>
            <CustomInput placeholder="Email/SĐT" />
          </Form.Item>
          <p className="mb-2 font-medium">Chọn phương thức nhập mã OTP</p>
          <Form.Item>
            <Radio.Group>
              <Radio value={1}> Số điện thoại </Radio>
              <Radio value={2}> Địa chỉ email </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item>
            <div className="grid grid-cols-4 gap-2">
              <Button onClick={onClose} className="h-[44px]">
                Chat CSKH
              </Button>
              <Button
                className="col-span-3 h-[44px]"
                type="primary"
                htmlType="submit"
                onClick={() => {
                  onClose();
                  handleShowSendingOtp();
                }}
              >
                Tiếp tục
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <SendingOtp open={showSendingOtp} onClose={handleCloseSendingOtp} />
    </>
  );
};
