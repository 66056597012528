import { Button, Form } from '@/UI';
import { useState } from 'react';
import { ForgottenPassword } from '../forgotten-password';
import { useRouter } from 'next/router';
import { APIResponse } from '@/interfaces';
import { showMessage } from '@/components/messages/GMessage';
import { login } from '@/services/Auth.service';
import CustomInput from '@/components/common/CustomInput';

const LoginCard = () => {
  const router = useRouter();
  const [form] = Form.useForm();
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const handleShowForgetPassword = () => {
    setShowForgotPassword(true);
  };

  const handleCloseForgetPassword = () => {
    setShowForgotPassword(false);
  };

  const onGamLogin = async () => {
    const res: APIResponse = await login(form.getFieldsValue());

    if (res.error) return showMessage.error(res.error);
    router.push('/');
  };

  return (
    <>
      <div className="flex flex-col text-center items-center h-[60vh] w-full">
        <p className="mt-16 text-2xl font-medium mb-1">Chào bạn đến với Gam!</p>
        <p className="text-th-sub-text mb-8">Nhập số điện thoại, email và mật khẩu để tạo tài khoản</p>
        <Form
          form={form}
          className="flex flex-col items-center w-full xl:gap-y-5 gap-y-3 mb-4"
          onFinish={onGamLogin}
        >
          <Form.Item className="w-full m-0" name="username">
            <CustomInput
              placeholder="Nhập số điện thoại hoặc email"
              className="w-full"
              allowClear
              autoFocus
            />
          </Form.Item>

          <Form.Item className="w-full lg:mb-0" name="password">
            <CustomInput type="password" placeholder="Nhập mật khẩu" />
          </Form.Item>
          <div
            className="w-full flex justify-start text-th-primary hover:cursor-pointer"
            onClick={handleShowForgetPassword}
          >
            Quên mật khẩu
          </div>

          <Button
            size="large"
            htmlType="submit"
            type="primary"
            className=" w-full bg-th-primary dark:text-black h-[48px]"
          >
            Hoàn thành
          </Button>
        </Form>
      </div>
      <ForgottenPassword onClose={handleCloseForgetPassword} open={showForgotPassword} />
    </>
  );
};

export default LoginCard;
