import Title from '@/components/common/modal/Title';
import { Button, Form, Input, Modal } from '@/UI';
import { useState } from 'react';
import { UpdatePassword } from './UpdatePassword';

export const SendingOtp = ({ onClose, open }) => {
  const [showUpdatePassword, setShowUpdatePassword] = useState(false);

  const handleShowUpdatePassword = () => {
    setShowUpdatePassword(true);
  };

  const handleCloseUpdatePassword = () => {
    setShowUpdatePassword(false);
  };
  return (
    <>
      <Modal
        className="custom-login-modal"
        title={<Title label="Xác thực bảo mật" onCancel={onClose} />}
        footer={null}
        closable={false}
        onCancel={onClose}
        width={500}
        open={open}
        centered
        destroyOnClose
      >
        <Form className="p-4 flex flex-col text-center items-center gap-4 justify-center">
          <p className="px-3 mb-0">
            Mã OTP đã được gửi về số điện thoại bạn đã đăng ký. Vui lòng kiểm tra và điền mã.
          </p>
          <Form.Item noStyle>
            <Input.OTP className="custom-otp" formatter={(str) => str.toUpperCase()} />
          </Form.Item>
          <span className="mb-8 mt-2">Yêu cầu gửi lại sau 25s</span>
          <Form.Item noStyle>
            <div className="grid grid-cols-4 gap-2">
              <Button onClick={onClose} size="large" className="h-[44px]">
                Chat CSKH
              </Button>
              <Button
                size="large"
                className="col-span-3 h-[44px]"
                type="primary"
                htmlType="submit"
                onClick={() => {
                  onClose();
                  handleShowUpdatePassword();
                }}
              >
                Tiếp tục
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <UpdatePassword open={showUpdatePassword} onClose={handleCloseUpdatePassword} />
    </>
  );
};
